.AppointmentsSpec {
    width: 100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    background-color: inherit;
    margin: 1rem 0rem;
    gap: 0.3rem;
    justify-content: center;
}

.addHour {
    font-size: 1.5rem;
    color: white;
    background-color: hsla(174, 40%, 63%, 1);
    border-radius: 20%;
    padding: 0.05rem 0.5rem;
    cursor: pointer;
    
}

.addHour:hover {
    transform: translateY(-3px); /* subtle move-up effect */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* subtle shadow for depth */
    background-color: hsla(174, 45%, 65%, 0.95);
  }


