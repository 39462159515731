.dialogOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
}

.dialogContainer {
    background-color: #fff;
    width: 80%;
    max-width: 20rem;
    border-radius: 1rem;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
}

.dialogHeader {
    background-color: hsla(174, 40%, 63%, 1);
    color: hsla(229, 17%, 24%, 1);
    font-weight: 700;
    
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    display: flex;
    justify-content: space-between;

}

.closeDialog {
    cursor: pointer;
    
}

.dialogMain {
    padding: 16px;
}

.dialogMain p {
    margin: 8px 0;
}

.dialogMain a {
    color: hsla(174, 40%, 63%, 1);
    text-decoration: none;
    margin-left: 0.2rem;
}
.dialogMain span {
    color: hsla(174, 40%, 63%, 1);
    text-decoration: none;
    margin-left: 0.2rem;
}

.dialogMain a:hover {
    text-decoration: underline;
}

.dialogMain label {
    display: flex;
    align-items: center;
}

.dialogMain input[type="radio"] {
    margin-right: 8px;
}

.dialogFooter {
    padding: 16px;
    text-align: center;
}
.AppoDialogButton {
    background-color: hsla(174, 40%, 63%, 1);
    color: hsla(229, 17%, 24%, 1);
    border: none;
    border-radius: 0.5rem;
    padding: 0.5rem 0.7rem;
    cursor: pointer;
    font-weight: 700;
    font-size: 0.8rem;
    transition: all 0.2s ease-in-out;
}

.ADSpan {
    color: rgb(4, 7, 19);
    font-weight: 700;
    font-size: 1rem;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}
