.Inputs {
    width: 90%;
      height: fit-content;
      border-radius: 10px;
      padding: 0.5rem;
      padding-left: 0.5rem;
      
}
.InputsDiv2 {
    display: flex;
    flex-direction: column;
    gap: 1.5vh;
    height: 100%;
}