.MiniHeader {
    box-sizing: border-box;
    width: 100%;
    background-color: white;
    border-radius: 5%;
    padding: 0.5em 0;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    transition: color 0.3s ease, transform 0.3s ease;
}

.MiniHeader a {
    flex: 1;
    display: flex;
    justify-content: center;
    text-decoration: none;
    font-weight: 400;
    color: hsla(0, 0%, 79%, 1);
    transition: color 0.3s ease, transform 0.3s ease;
}

.MiniHeader a:hover {
    color: hsla(184, 52%, 69%, 1);
}

.MiniHeader a.active {     
    color: hsla(184, 52%, 69%, 1);
    border-bottom: 0.33vh solid hsla(184, 52%, 69%, 1);
    transform: scale(0.9);
}
