.ProfilePage {
    background-color:hsla(0, 0%, 95%, 1);
    min-height:100vh;
    font-family: 'Poppins', 'Helvetica Neue', 'Arial', 'Roboto', sans-serif;
    position:relative;
    overflow: hidden;
    
    
}

.ProfileContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin:1.3vh;
    gap:1vh;
    position: relative;
    
    margin-bottom: 5rem;
    
}

.MiniProfileContainer{
  width:min(34.37rem,95%);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1vh;
 
  
}
/* General hover effect for button-like elements */
.AddDescription:hover,
.AddToHome:hover , .CalendarSymbol:hover {
  transform: translateY(-3px); /* subtle move-up effect */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* subtle shadow for depth */
}

/* Shadow and increased contrast for .VideoOverlay on hover */
.VideoOverlay:hover {
  background-color: hsla(174, 40%, 60%, 0.95);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
}

/* Profile image shadow and scaling effect on hover */
.ProfileImageContainer:hover .ProfileImage {
  transform: translate(-50%, -50%) scale(1.05); /* slight scaling */
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.15);
}

/* Hover effect for .AddToHome for better visual feedback */
.AddToHome:hover  {
  background-color: hsla(174, 45%, 65%, 0.95);
}

/* This ensures that the hover effect doesn't cause other elements to move */
.AddDescription,  .ProfileImage, .AddToHome , .CalendarSymbol{
  transition: all 0.3s ease; /* smooth transitions */
}

.hoverable {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out; /* Smooth transition for all changes */
  padding: 5px 10px; /* Add some padding */
  border-radius: 5px; /* Rounded corners for the background */
}

.hoverable:hover {
  color: #fff; /* Light color text on hover */
  background-color: hsla(174, 60%, 40%, 1); /* Darker background on hover */
  transform: scale(1.05); /* Slightly increase the size */
}

.hoverable svg {
  fill: currentColor; /* Ensure the SVG arrow uses the text color */
  transition: fill 0.3s ease-in-out; /* Smooth transition for SVG color change */
}







