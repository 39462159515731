.Root {
    height: fit-content;
    min-height: 100vh;
    background-color: hsla(0, 0%, 95%, 1);
    font-family: 'Poppins', 'Helvetica Neue', 'Arial', 'Roboto', sans-serif;
    position: relative;
    display: flex;
    flex-direction: column;
}
.CardsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin:1.3vh;
    gap:1vh;
    position:relative;
    height: fit-content;
    align-items: center;
    
    }
.RootMiniContainer{
    width: min(40rem, 90vw);
    min-width: 50%;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1vh;
    align-items: center;
}

.Search {
    background-color: white;
    align-items: center;
    padding: 0.8rem 0rem;
    font-size: 0.9rem;
    color: hsla(0, 0%, 70%, 1);
    font-weight: 400;
    font-style: italic;
    border-radius: 0.5rem;
    display: flex; 
    box-sizing: border-box; 
    width: 100%;
}
.MGlass {
    margin:0 0.5rem;
    cursor: pointer;
}

.SearchInput {
    border: none;
    font-style: italic;
    height: 100%;
    width: 100%; /* Add this line */
    box-sizing: border-box;  /* Add this line */
    outline: none; /* Removes the default focus outline */
    background: none; /* Removes any default background styling */
    padding: 0; /* Removes any default padding */
    margin: 0; /* Removes any default margin */
}

.SearchInput:focus {
    outline: none; 
    color: hsla(0, 0%, 70%, 1);
    
}
.AvatarContainer {
    display: flex; 
    
}

.custom-tooltip-class {
    position: relative;
    top:10rem /* Adjust the value as needed */
  }

.addToHomeInstructionVideo {
   
        position: absolute;
        bottom: min(5rem,16vh);
        right: 3rem;
        width: 25%;
        height: 35%;
        object-fit: cover;
        background-color: white;
        border-radius: 50%;
        cursor: pointer;
}





