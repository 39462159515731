.HoursContainer {
    display: grid;
    height: fit-content;
    grid-template-columns: repeat(5, 1fr);
    position: relative;
    column-gap: 0.5rem;
    margin-bottom: 0.5rem;
    
    
    
   
}

.HoursNumber {
    width: fit-content;
    color: hsla(229, 17%, 24%, 1);
    font-weight: 400;
    font-size: 0.8rem;
    margin-left: 0.2rem;
    pointer-events: none;
    
}

.Hours {
    width: 90%; 
    background-color: white;
    display: flex;
    justify-content: start;
    align-items: center;
    margin:0.2rem 0;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-radius: 0.6rem;
    padding-left: 0.5rem;
}

.Hours:hover {
    background-color: hsla(229, 17%, 34%, 0.1); /* Light background color change on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adds shadow on hover */
    cursor: pointer; /* Changes the cursor to a hand pointer */
}




