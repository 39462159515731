.Appointments {
    width: 100%;
    display: flex;
    flex-direction: column;   
}
.Appo {
    display:flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: white;
    border-radius: 0.6rem;
    padding: 0.5rem 1.2rem;
    box-sizing: border-box;

}

.AppoProfile {
    display: flex;
    justify-content: center;
    align-items: center;
}

.AppoImageContainer{
    height: 2rem;
    position: relative;
    
}
.AppoImage{
    height: 100%;
}
.AppoInfo{
    margin:0rem 0.5rem;
}

.AppoName{
    font-size: 0.85rem;
    color: hsla(229, 17%, 24%, 1);
    font-weight: 700;
    font-family: 'Inter', 'Roboto', 'Helvetica Neue', 'Arial', sans-serif;
}

.AppoDate{
    font-size: 0.75rem;
    color: hsla(0, 0%, 78%, 1);
    font-family: 'Poppins', 'Helvetica Neue', 'Arial', 'Roboto', sans-serif;
    font-weight: 400;
} 

.AppoButton {
    margin:0rem 0.25rem;
    background-color: white;
    border-radius: 1rem;
    border: 0.05rem  solid hsla(229, 17%, 24%, 1);
    padding:  0.15rem 0.7rem;
    color: hsla(229, 17%, 24%, 1);
    font-family: 'Poppins', 'Helvetica Neue', 'Arial', 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 0.8rem;
    cursor: pointer;
}