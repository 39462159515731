* {
  margin : 0;
  user-select: none;
    /* Standard */
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
}

.App {
  position: relative;
  height: 100%;
  
}