.Manual {
height: fit-content;
min-height: 100vh;
background-color: hsla(0, 0%, 95%, 1);
font-family: 'Poppins',
'Helvetica Neue',
'Arial',
'Roboto',
sans-serif;
position: relative;
display: flex;
flex-direction: column;
align-items: center;


}

.manualText {
    width: 50rem;
    padding-top: 4rem;
    padding-bottom: 4rem;
    max-width: 90%;
}

.notesImage{
    width: 50rem;
    max-width: 90%;
}