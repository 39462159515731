
.ProfilePage{
  display: flex;
  flex-direction: column;
}

.ProfileCard {
    width: 100%;
    margin-right: 2vw;
    position: relative;
    left: 1vw; 
     
}
.VideoProfileCard{
    position:relative;
    height:9.80rem;
    width: 100%
   }
.Video {
    position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-top-left-radius: 1.2vw;
      border-top-right-radius: 1.2vw;
}

.VideoOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color:  hsla(174, 40%, 63%, 0.9);
    border-top-left-radius: 1.2vw;
    border-top-right-radius: 1.2vw;
  }

  .ProfileRating {
    display: flex;
    justify-content: center;
    align-items: center;
    width:3rem;
    padding: 0.3rem 0rem;
    border-radius: 20px;
    background-color:hsla(219, 16%, 6%, 0.5);
    position:absolute;
    top:8%;
    right:2.5%;
    z-index:4;
    font-size:0.75em;
    font-weight: 600;
    color: hsla(0, 0%, 100%, 1);
    font-family: 'Inter', 'Roboto', 'Helvetica Neue', 'Arial', sans-serif;;
  }
  

.NotVideoProfileCard{
    background-color: hsla(0, 0%, 100%, 1);
    height:fit-content;
    width:100%;
    border-bottom-left-radius:1.2vw;
    border-bottom-right-radius:1.2vw;
    display:flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    gap:1vh; 
    position: relative; 
    flex-shrink: 0;
  
}

.ProfileImageContainer {
    background-color: white;
    border-radius:50%;
    width:clamp(6rem,25vw,7rem);
    height: clamp(6rem,25vw,7rem);
    position:absolute;
    top: 9.5rem;
    left: 50%;
    transform: translate(-50%, -50%);
}

.ProfileImage{
  position:absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width:86% ;
  height:86%;
  
}
.ProfileName {
 font-weight: 600;
 color:hsla(219, 16%, 35%, 1);
 padding-top: 7%;
 font-size: 1.3em;
 margin-top: 1rem;
 flex-shrink: 0;
}

.ProfileDescription, .AddDescription {
  color:hsla(0, 0%, 53%, 1);
  font-weight: 400;
  font-size: 0.8em;
  text-align: center;
  padding:0 2%;
  width: 95%; 
  word-wrap: break-word;
  margin-bottom: 0.5rem;
}
.AddDescription{
  font-weight: 600;
  font-size: 0.8rem;
  cursor: pointer;
}

.PlusContainer {
  border-radius: 50%;
  position:absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0.3rem;
  right: 1.6rem;
  width: 1.4rem;
  height: 1.4rem;
  background-color: rgba(14, 179, 168, 0.97);
  color: white;  
  cursor: pointer; 
}

.AddToHome {
  position:absolute;
  top: 15%;
  left: 8%;
  font-size: 1.5rem;
  background-color: hsla(174, 40%, 63%, 0.9);
  border-radius: 20%;
  padding:0rem 0.6rem; 
  color: white;
  cursor: pointer;
  
}
.AddToHomeLoading {
  position:absolute;
  top: 15%;
  left: 8%;
  font-size: 1.5rem;
  background-color: hsla(174, 40%, 63%, 0.5);
  border-radius: 20%;
  padding:0rem 0.6rem; 
  color: white;
  cursor: wait;
  
}

.messageImage{
  position: absolute;
  z-index: 10;
  width: clamp(2.3rem, 7vw, 2.5rem);
  top:5.5%;
  left:3%;
  cursor: pointer;
}

.CalendarSymbol {
  position:absolute;
  top: 8%;
  right: 8%;
  font-size: 1.5rem;
  border-radius: 20%;
  padding:0rem 0.6rem; 
  color: white;
  cursor: pointer;
  width: 2.3rem;  
  display: none;
}
.nonClickable {
  cursor: not-allowed;
}

@media(max-width:1470px) {
  .CalendarSymbol{
    display: block;
  }
 }

 

