.Header {
    display:flex;
    height:3.5rem;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    width: 100%;  
    
}
.Lists{
    display: inline-flex;
    flex: 1 1 100%;
    gap: 0.5 vw;
    justify-content: center;
    position: relative;
    right: 5.5rem;
    
}

.Logo2{
    display:inline-flex;
    flex:0 1 20%;
    justify-content: end;
    height: 100%;
    margin-left: 10%;
    outline: 'none'
}

.List,  .LogOut {
    margin-left: 1vw;
    margin-right: 1vw;
    font-weight: 400;
    color: hsla(0, 0%, 70%, 1);
    background: none;
    border: none;
    font: inherit;
    text-decoration: none;
    position: relative;
    height:fit-content;
    font-size: 0.9em;
    display: flex;
    align-items: center;
    
}

.List1 {
    margin-top: 0.5rem;
}

.Profile{
    margin-left: auto;
    margin-right: 1vw;
    font-weight: 400;
    color: hsla(0, 0%, 70%, 1);
    background: none;
    border: none;
    font: inherit;
    text-decoration: none;
    position: relative;
    height:fit-content;
    font-size: 0.9em;
    display: flex;
    align-items: center;
    flex: 0 1 auto;
    min-width: fit-content;
    
}
.LogOut{
    display: flex;
    flex:0 1 auto;
    justify-content: flex-end;
    font-size: 0.6rem;
    font-weight: 600;
    position: relative;
    top: 0.2rem;
   
    
}

.selected {
   color:  hsla(219, 16%, 35%, 1);
   font-weight: 600;
   
}

.List:hover,  .LogOut:hover { 
    cursor: pointer;
    transform: scale(1.05);
    transition: all 0.3s ease;
}
.ProfileImage{
    border-radius: 50%;
    position:relative;
    right:3vw;

}
.ProfileImage2{
border-radius: 50%;
width:3em;
margin-right: 0.5em;


}
.HeaderName {
    font-size: 0.80em;
    position: relative;
    top:0.2em;
    flex: 0 0 auto;
    margin-right: 1vw;
    
}

.Logo2:hover {
    cursor: pointer;
    transform: scale(1.05);  /* You can replace this with your desired style */
    transition: all 0.3s ease;
}
.Logo2:focus {
    outline: none;
}


.Dropdown {
    border:none;
    font-weight: 400;
    color: hsla(0, 0%, 70%, 1);
    font-size: 0.9rem;
    background-color: white;
    flex: 0 0 auto;
    position: relative; 
    cursor: pointer;
    
}

.Dropdown:focus {
    border: none !important;
    outline: none;
}


@media (min-width:600px) {
   .Profile{
    position: relative;
    right: 5vw;
   }
}
@media (max-width:500px) {
   .Profile{
    position: relative;
    left:3vw;
   }
   .HeaderName{
    width: 2rem;
    margin-right: 3rem;
   }
}

.settingsDropdown {
    position: absolute;
    right: -3rem;
    top: 2.78rem;
    background-color: white;
    border: 1px solid #ccc;
    padding: 10px;
    z-index: 5000;
    width: 10rem;
    margin-right: 1rem;
}

.settingsDropdownLi{
    margin-bottom: 0.5rem;
}

.Profile , .settingsDropdown {
    cursor: pointer;
}

.nav-link {
    color: black;
    text-decoration: none;
    font-weight: 400;
    color: hsla(0, 0%, 70%, 1);
    background: none;
    border: none;
    font: inherit;
}

.nav-link-active {
    color: red;
    text-decoration: none;
}

.nav-link:hover {
    color: black; 
}

.drop-down-button{
    font-size: 0.7rem;
    display: flex;
    position: relative;
    top: 1rem;
    right: 0.2rem ;
    margin-right: 1rem;
}

header {
    width: 100%;
}




