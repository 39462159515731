.About {
    background-color: white;
    min-height: 25vh;
    width:100%;
    border-radius: 5%;
    box-sizing: border-box;
    overflow:wrap;
    
}

.SpecializationHeader {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 0.6rem 0.5rem;
    border-bottom: 0.1rem solid hsla(0, 0%, 85%, 1);
}
.SpecializationText{
    margin-left: 0.5rem;
    position: relative;
    color:hsla(219, 16%, 35%, 1);
    font-family: 'Inter', 'Roboto', 'Helvetica Neue', 'Arial', sans-serif;
    font-weight: 600;
    font-size: 0.9rem;
}

.Specs2{
    display:flex;
    font-size: 0.8em;
    
}

.S11, .S22, .S33 { 
    
    justify-content: center;
    align-items: center;
    padding:0.2rem;
    border-radius: 2vw;
    font-weight:400;
    color: hsla(219, 16%, 35%, 1);
    margin:0.8rem 0.1rem;
    font-size: 0.8rem;
    padding: 0.4rem min(0.3rem,1vw);
    max-width: 8rem;
    overflow-wrap: break-word;
    height: fit-content;
}
.S11{
    background-color: hsla(29, 100%, 89%, 0.4);
    ;
}

.S22 {
    background-color: hsla(341, 65%, 88%, 0.3);
}
.S33 {
    background-color: hsla(184, 44%, 81%, 0.3);

}
.plusSpecialization{
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight:600;
    color: hsla(219, 16%, 35%, 1);
    margin:1vh;
    font-size: 0.8rem;
    padding: 0.5vh 1.4vh;
    cursor:pointer;
    font-family: 'Inter', 'Roboto', 'Helvetica Neue', 'Arial', sans-serif;
}
.PopUpInput{
    margin: 0.2rem;
    border-radius: 0.3rem;
}
.PopUpButton{
    border-radius: 0.3rem;
    color:black;
    background-color: aquamarine;
    cursor: pointer;
   
}

.AboutMe{
    margin-top: 1rem;
    background-color:white;
    display: flex;
    width: 100%;
    align-items: center;
    border-bottom: 0.1rem solid hsla(0, 0%, 85%, 1);
    font-weight:600;
    color: hsla(219, 16%, 35%, 1);
    font-size: 0.9rem;
    gap: 0.5rem;
    box-sizing: border-box;
    padding-left: 0.5rem;
    
}

.AboutMeImage{
    width: 1.75rem;
}

.AddDescription {
    margin-top: 0.5rem;
    font-size: 0.9rem;
    color: hsla(174, 40%, 63%, 1);
    border-bottom:  0.1rem solid hsla(0, 0%, 85%, 1);
    padding-bottom: 0.3rem;
    cursor: pointer;
}

.AboutMeDescription{
   overflow-wrap: break-word;
   margin: 0.5rem;
   font-size: 0.9rem;
   color: hsla(219, 16%, 40%, 1);
   
}

