.arrow-button2 {
    width: 100%;
    height: 2rem;
    background-color: hsla(219, 16%, 35%, 1);
    border-radius: 5px;
    font-family: 'Poppins', 'Helvetica Neue', 'Arial', 'Roboto', sans-serif;
    color: white;
    display: flex;
    align-items: center;
    justify-content: flex-start; 
    font-size: 0,8rem;
    margin-top: 0.7rem;
  }

  .arrowButtonText2{
    flex:1 1 auto;
    display:flex;
    justify-content: center;
    align-items:center;

  }
  .arrow-button2:hover {
    background-color: hsla(219, 26%, 45%, 1); /* Change the color or other properties as desired */
    cursor: pointer; /* Changes the cursor to a hand pointer */
  }
  
  .arrow-button2:hover .arrow2 {
    color: rgb(0, 255, 213); /* Changes the color of the arrow when hovering over the button */
    transform: scale(1);
  }
  
  .arrow2 {
    flex-shrink: 0;
    color: white;
    margin-left: 0.8rem;
    transform: scale(1.2); /* Shrink the arrow */
    transition: transform 0.3s ease; /* Smooth transition */
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
    
    
    
  }

  @media(width<450px) or (height<300px)  {
    .arrow2{
      font-size: 1.4rem;
      position: relative;
      bottom: 0.25rem;
    
    }
    .arrowButtonText2{
      position: relative;
      right: 5vw;
      
    }
  }

 