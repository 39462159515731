.PrivacyPoliceContainer {
    height: fit-content;
    min-height: 100vh;
    background-color: hsla(0, 0%, 95%, 1);
    font-family: 'Poppins', 'Helvetica Neue', 'Arial', 'Roboto', sans-serif;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    
}
.PrivacyPolice {
    padding: 2rem;
    width: min(1000px,80%);
    background-color: white;
    margin-bottom: 5rem;  
}

.privacyPoliceDiv {
    display: flex;
    font-size: 1rem;
    font-family: 'Poppins', 'Helvetica Neue', 'Arial', 'Roboto', sans-serif;
    margin: 4rem;
    font-weight: 800;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.privacyPolicyCheckBox {
    width: 1rem;
    height: 1rem;
    position: relative;
    cursor: pointer;
}

.PrivacyPoliceButton {
    width: 12rem;
    background-color: rgb(149, 54, 54);
    color: white;
    border-radius: 1rem;
    padding: 0.3rem;
    cursor: pointer;
    font-weight: 1.2rem;
}

.hoverablePrivacyPoliceButton {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    /* Smooth transition for all changes */
    padding: 5px 10px;
    /* Add some padding */
    border-radius: 5px;
    /* Rounded corners for the background */
}

.hoverablePrivacyPoliceButton:hover {
    color: #fff;
    /* Light color text on hover */
    background-color: red;
    /* Darker background on hover */
    transform: scale(1.05);
    /* Slightly increase the size */
}

.DropdownPP {
    border-color:white;
    font-weight: 400;
    color: #5ea9a1;
    font-size: 1.2rem;
    background-color: white;
    position: relative;
    cursor: pointer;
    outline: 'none'
}
.DropdownPP :focus {
    outline: none;
    border-color: white;
    outline: 'none'
}
