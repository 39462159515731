.Booking {
    background-color: hsla(0, 0%, 95%, 1);
    height: fit-content;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    
}
.BookingContainer {
    position: relative;
    display: flex;
    gap: 0.4rem;
    flex-direction: column;
    width: min(34.4rem,95%);
    align-items: center;
}
.TherapyFileContainer{
    box-sizing: border-box;
    background-color: white;
    
    border-radius: 1rem;
    
    
    padding: 0.5rem 1rem;
    width: 100%;
}
.TFtext {
    color:hsla(229, 17%, 24%, 1);
    font-size: 0.8rem;
    
   font-family: 'Poppins', 'Helvetica Neue', 'Arial', 'Roboto', sans-serif;
   font-weight: 600;
   margin-bottom: 0.3rem;
   display: flex;
   position: relative;
   
}

.HoursBigContainer {
    background-color: white;
    border-radius: 0.2rem;
    width: 99%;
    padding-left: 1rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}
.HoursBigContainer div div {
    background-color: hsla(0, 0%, 98%, 1);
}

.PayContainer {
    background-color: white;
    width: 95%;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column; 
    font-family: 'Poppins', 'Helvetica Neue', 'Arial', 'Roboto', sans-serif;
    font-size: 0.9em;
    gap: 0.3rem;
    padding: 1rem;
}

.PayLabel{
    color: hsla(229, 17%, 24%, 1);
    font-weight: 600;
    font-size: 0.85em;
}
.PayDescription{
    border: 0.1rem  solid hsla(0, 0%, 93%, 1);
    border-radius: 0.5rem;
    padding: 0.2rem;
    font-size: 0.85rem;
    color: hsla(0, 0%, 60%, 1);
    font-weight: 500;
    overflow-wrap: break-word;
}
.Alert{
    justify-content: start;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;   
}
.PayButton {
    width: 100%;
    background-color: hsla(174, 40%, 63%, 1);
    border-radius: 0.5rem;
    padding: 0.5rem 0rem;
    color: hsla(229, 17%, 24%, 1);
    font-family: 'Roboto', 'Helvetica Neue', 'Arial', sans-serif;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0rem  0rem 0.5rem 0rem;
    border: none;
    font-size: 0.85em;
    cursor:pointer
}

.HoursBigContainer .activeCheckBox {
    background-color: hsla(229, 17%, 24%, 1);
}

 .activeCheckBox2 {
    background-color: hsla(229, 17%, 24%, 1);
}

.EmptyDayText {
    
  width: 100%;
  height: 100%;
  display:flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
   
}

.TherapyFileBox {
    
    background-color: hsla(0, 0%, 98%, 1);
    padding-left: 1rem;
}
.ButtomTherapyFileText{
    color:hsla(229, 17%, 24%, 1);
}

.Alert{
    display: flex;
    align-items: center;
}







