
.Chat {
    background-color: hsla(0, 0%, 95%, 1);
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;

}
.ChatContainer{
    display: flex;
    background-color: hsla(0, 0%, 95%, 1);
    box-sizing: border-box;
    flex: 1 1 auto;
    height: 100%;
    overflow: none;
    
}
.chat-list{
    flex:1 1 30%;
    background-color:  hsla(0, 0%, 92%, 1);;
    height: 100%;
    
    
    position: relative;
    padding-top: 0.1rem;
    box-sizing: border-box;
}
.MIContainer{
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding-bottom: 1rem;
    height: 100%;
    position: relative;
    
    flex:1 1 70%;
    box-sizing: border-box;
    
}
.ChatHeader {
    margin: 0rem;
}


.beActive {
    background-color: hsla(0, 0%, 85%, 1) !important; 
    
}

.rce-container-mbox {
    width: 100%;   
}
.rce-mbox-right{
    background-color:hsla(174, 40%, 63%, 1);  
}
 .MessageList{
    overflow:scroll; 
    position: relative;
    width:100%;
    height :85vh;
    margin-bottom:2rem ;
    
    
     
 }

 .rce-container-citem{
    width: 100%;
 }
 .rce-mbox {
    max-width: 45%;
  
 }
 .rce-mbox-right-notch{
    fill:hsla(174, 40%, 63%, 1); 
 }

 .LoadDiv {
    font-size: 0.9rem;
    color: hsla(174, 40%, 63%, 1);
    margin-bottom: 0.5rem;
    cursor: pointer;
    margin: 0.5rem;
    user-select: none;
 }
 .rce-citem-body {
    height: 3rem;
 }
 .messageArrow {
    color: hsla(174, 40%, 63%, 1);
    font-size: 2rem;
    max-width: 1.5rem;
    overflow: hidden;
    height: fit-content;
    position: relative;
    cursor: pointer;
    font-weight:900;
 }
 .LoadBackContainer{
    display: flex;
    justify-content: space-between;
    align-items: start;
    
    padding-left: 1rem;
 }
 .rce-container-input {
   align-items: center;
   background: #fff;
   box-sizing: border-box;
   display: flex;
   flex-direction: row;
   position: fixed;
   bottom: 0rem;
   width: 70.5%;
   z-index: 100;
   min-width: 0rem;
   
}
 .MessageInput {
   
    padding-right: 1vw;
}
.MessageInput:focus {
   border-right: none;
   outline: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.rce-container-input:focus {
   border-right: none;
   outline: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}


 @media(width<450px) or (height<300px)  {
    .messageArrow{
      font-size: 3rem;
      font-weight: 900;
      position: relative;
      bottom: 1.5rem;
      max-height: 1rem;
      overflow: visible;
      
    } }
    @media(width<760px){
      .rce-container-input{
      width: 100%;
    }
      
   }
    @media(width>760px){
      .messageArrow{
      display: none;
      
    }
      
   }

