  .loginPageRoot {
      display: flex;
      height: max(100vh, fit-content);
      width: 100%;
      position: relative;
      overflow-y: hidden;

    }
  .loginPageAside {
      min-height: 100vh;
      flex:0 1 34.56%;
      min-width: 3rem;
      position: relative;
      
    }
    .loginPageAside .loginPageVideo {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;

      object-fit: cover;
      z-index: -1;
      height: 100%;
    }  

    .loginPageAside::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(123, 199, 191, 0.9);
    }
    
    .loginPageMain {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: relative;
      align-items: center;
      justify-content: center;
    }

    .loginPageAside .evaLogo {
      position: relative;
      z-index: 1;
      left:26.78%;
      top:43.43%;
  }
  
    .signInH{
      font-family: "Inter";
      color: #4B5567;
      width: fit-content;
      height: fit-content;
      font-size: clamp(1rem,5vh,1.5rem);
      margin-bottom: 1rem;
      }
      .upperButtons{
        display:flex;
        gap: 1.5vw;
        justify-content: start;
        width: 100%;
        position: relative;
 }
  .clientButton,.therapistButton {
    position: relative;
    display: flex; 
    width: 43%;
    height: 3rem;
    margin-bottom: 2rem;
    justify-content: space-between; 
    border-radius: 10px;
    border-color: #DCDCDC;
    align-items: center;
    
      
       }
  
  .inputsDiv {
    display: flex;
    flex-direction: column;
    gap: 3vh;
    width: 100%;
  }

  .ASA , .ASA2{
 
  font-size: 0.7rem;
  font-family: 'Poppins', 'Helvetica Neue', 'Arial', 'Roboto', sans-serif;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  margin-right: 1vw;
  }

  
  
  
  .NOTASA{

font-family: 'Poppins', 'Helvetica Neue', 'Arial', 'Roboto', sans-serif;
font-size: 1rem;
font-weight: 700;
line-height: 36px;
letter-spacing: 0em;
text-align: left;
margin-right: 1vw;

  }

  .NOTASA2{

font-family: 'Poppins', 'Helvetica Neue', 'Arial', 'Roboto', sans-serif;
font-size: 1rem;
font-weight: 700;
text-align: left;
margin-right: 1vw;
border:none;
margin-bottom: 0.05rem;

  }
  .Svg2 {
    min-width: 5rem;
    max-width: 5rem;
    min-height: 5rem;
    max-height: 5rem;
  }
  .Svg1 {
   height: 3rem;
  }
.inputsP {
font-family: 'Inter', 'Roboto', 'Helvetica Neue', 'Arial', sans-serif;
font-size: 1rem;  
font-weight: 600;
text-align: left;
margin-bottom: 0.5rem;


  }
  .realInput {
      width: 90%;
      height: fit-content;
      border-radius: 10px;
      padding: 0.3rem 0rem;
      padding-left: 0.5rem;
      
    }
    .signInButton {
      height: fit-content;
      background-color: #7BC7BF;
      border-radius: 10px;
      padding: 0.5rem;
      width: 92%;
      
      
    }

.P2 {
font-family: 'Poppins', 'Helvetica Neue', 'Arial', 'Roboto', sans-serif;
font-size: 1rem;
font-weight: 400;
text-align: left;


    }
.registerButton {
font-family: 'Poppins', 'Helvetica Neue', 'Arial', 'Roboto', sans-serif;
font-size: 0.8rem;
font-weight: 600;
text-align: left;
border:none;
background-color: white;

    }

    

    .upperText {
      font-family: 'Inter', 'Roboto', 'Helvetica Neue', 'Arial', sans-serif;
      display: flex;
      gap: 0.5rem;
      cursor: pointer;

    }

.clientButton:hover,
.therapistButton:hover,
.signInButton:hover
 {
  background-color: #5ea9a1;  /* You can change the color as you like */
  cursor: pointer;
  transition: all 0.3s ease;
}
.registerButton:hover{
  color: #5ea9a1;  /* You can change the color as you like */
  cursor: pointer;
  transition: all 0.3s ease;
}

/* Adding hover state to select dropdown */

.NOTASA2:hover {
  background-color: #E8E8E8;  /* You can change the color as you like */
  cursor: pointer;
  transition: all 0.3s ease;
}

.realInput:hover {
  border: 1px solid #5ea9a1;  
  transition: all 0.3s ease;
}

.ASA:hover,
.ASA2:hover,
.NOTASA:hover,
.inputsP:hover,
.P2:hover
 {
  color: #5ea9a1;  /* You can change the color as you like */
  cursor: pointer;
  transition: all 0.3s ease;
}

.P2:hover{
  cursor: default;
}

.Svg1:hover,
.Svg2:hover {
  filter: brightness(0.8);
  cursor: pointer;
  transition: all 0.3s ease;
}

.SolutionContainer1 , .SolutionContainer2 {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: min(40.5rem,80%);
  height: fit-content;
  justify-content: center;
  position:relative;

  
}

 @media (orientation:portrait) or (max-width:1000px) {
  .therapistButton {
    width: 55%;
  }
  .clientButton {
    width: 48%;
  }}
 @media(min-height:500px) {
    .mainContainer{
      position: relative;
      bottom: 5rem;
    }
 }

 .passwordButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 0.8rem;
 }

 .privacyPolicyLabel {
    font-size: 1rem;
    font-family: 'Poppins', 'Helvetica Neue', 'Arial', 'Roboto', sans-serif;
 }

 .privacyPolicyCheckBox {
  width: 1rem;
  height: 1rem;
  position: relative;
  cursor: pointer;
  
 }


 


  






 

  
  