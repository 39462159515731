.TherapyFile {
    width:100%;
    display:flex;
    gap:0.5rem;
    position: relative;
    margin: 0.3rem 0rem;
    
    
}
.TherapyFileBox {
    width: 47%;
    height: 3rem;
    background-color: white;
    padding-left: 1rem;
    padding-top: 0.5rem;
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    background-color: hsla(0, 0%, 97%, 1);
    border: 0.5rem thick black;;
}
.UpperTherapyFileBox{
    display:flex;
    justify-content: start;
    align-items: start;
    font-size: 0.8rem;
    gap: 0.2rem;
    color: hsla(229, 17%, 24%, 1);
    font-weight: 600;
}

.TherapyBoxText2{
    font-weight: 400;
}
.CheckBox2 {
    position: relative;
    top:-0.08rem;
    -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Safari */
  -khtml-user-select: none;    /* Konqueror HTML */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;
}

.ButtomTherapyFileText {
    color:hsla(0, 0%, 78%, 1);
    font-size: 0.75rem;
    font-weight: 500;
    margin-left: 0.6rem;
    margin-top: 0.2rem;
}
.TherapyFileBox {
    /* existing styles */
    transition: box-shadow 0.3s ease, transform 0.3s ease; /* Adds smooth transition for shadow and transformation */
}

.TherapyFileBox:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adds shadow on hover */
    transform: translateY(-2px); /* Gives a slight lifting effect on hover */
    cursor: pointer; /* Changes the cursor to a hand pointer */
}
@media (width<500px) {
    .TherapyFileBox{
        height: 4.2rem;
    }}



