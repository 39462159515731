.Footer {
    display: flex;
    height: 3.5rem;
    align-items: center;
    background-color: white;
    width: 100%;
    background-color: white;
    margin-top: auto;
    justify-content: center;
}

.LogoFooter {
    display: inline-flex;
    flex: 0 1 20%;
    justify-content: end;
    height: 100%;
    margin-left: 10%;
    margin-right: auto;
}
.FooterA {
    color: black;
    text-decoration: none;
 }

 .instagram-icon svg, .gmail-icon, .website-icon {
     color: #5ea9a1;
     /* Instagram brand color */
 }

 .legal {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: min(2rem,5vw);
    font-size: min(0.7rem,2.3vw);
    color: black;
    justify-content: center;
    gap: 0.3rem;
 }

 .legal :hover {
    color: #5ea9a1;
 }
.icons-div {
    display: flex;
    gap: 2vw;
    position: relative;
    right: 10vw;
}

@media (orientation:portrait) or (max-width:300px) {
    .icons-div {
        right: 5vw;
    }
}