.SpecDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: white;
    padding: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 0.5rem;
    font-size: 0.75rem;
    position: relative;
    box-sizing: border-box;
    /* cursor: pointer; */
    
    
    
}
.SpecSymbol{
    font-size: 1.8rem;
    color:hsla(229, 17%, 24%, 1)
    
}
.SpecHour{
    color:hsla(229, 17%, 24%, 1);
    font-weight: 400;
}

.SwitchContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.3rem;
}

.PictureContainer {
    height: 2rem;
    margin-top: 0.2rem;
}

.SpecDivPicture {
    height: 100%
    
}

.SpecSymbol {
    font-weight: 100;
    font-size: 1.2rem;
    cursor: pointer;
}