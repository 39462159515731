.RatingsList {
    background-color: white;
    height: fit-content;
    width:100%;
    border-radius: 5%;
    padding: 0.7rem 0.9rem;
    box-sizing: border-box;
}
.RatingsHeader{
    padding: 0.5rem 0rem;
    border-bottom:  0.1rem solid hsla(0, 0%, 85%, 1);
    display: flex;
    align-items: center;
    gap:0.4rem;
    font-weight: 600;
    color: hsla(219, 16%, 35%, 1);
    font-family: 'Inter', 'Roboto', 'Helvetica Neue', 'Arial', sans-serif;
    font-size: 0.95rem;
}
.addRating {
    margin-top: 0.5rem;
    font-size: 0.9rem;
    color: hsla(174, 40%, 63%, 1);
    border-bottom:  0.1rem solid hsla(0, 0%, 85%, 1);
    padding-bottom: 0.3rem;
    cursor: pointer;
}

.RatingsBox {
    margin-top: 0.5rem;
    font-size: 0.8rem;
    border-bottom:  0.1rem solid hsla(0, 0%, 85%, 1);
    padding-bottom: 0.6rem;
    font-family: 'Inter', 'Roboto', 'Helvetica Neue', 'Arial', sans-serif;
    font-weight: 300;
    position: relative;
    overflow-wrap: break-word;
    
}

.UpperRatingsBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    
}
.LeftUpperRatingsBox {
    display: flex;
    align-items: center;
    gap: 0.45rem;
}
.RightUpperRatingsBox {
    display: flex;
    align-items: center;
    gap: 0.2rem;
}
.RatingsImage {
    vertical-align: middle;
    width: 2rem;
    height: 2rem;
    border-radius: 50%
}

.RatingsComent {
    padding: 0rem 2.5rem;
    color:hsla(0, 0%, 53%, 1);
    margin:0.3rem 0rem;
    width: 90%;
}
.RatingsDate {
    color:hsla(0, 0%, 73%, 1)
}

.RatingsNumber{
    color:hsla(229, 17%, 24%, 1);
    font-weight: 600;
    font-size: 0.8rem;
}

.RatingsName {
    font-weight: 400;
    color:hsla(229, 17%, 24%, 1);
    font-size: 0.9rem;
}

.LoadMoreButton{
    background-color: white;
    border: none;
    border-radius: 0.5rem;
    padding: 0.8rem 0rem;
    font-size: 0.9rem;
    color: black;
    font-weight: 500;
    font-style: italic;
    display: flex; 
    box-sizing: border-box; 
    width: fit-content;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 1vh;
}
.LoadMoreButtonLoading{
    background-color: white;
    border: none;
    border-radius: 0.5rem;
    padding: 0.8rem 0rem;
    font-size: 0.9rem;
    color: gray;
    font-weight: 500;
    font-style: italic;
    display: flex; 
    box-sizing: border-box; 
    width: fit-content;
    justify-content: center;
    align-items: center;
    cursor: wait;
    margin-bottom: 1vh;
}