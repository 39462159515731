.Alert {
    margin: 0.5rem 0;
    font-family: 'Poppins', 'Helvetica Neue', 'Arial', 'Roboto', sans-serif;
    font-size:0.7rem;
    font-weight: 500;
    color:hsla(219, 16%, 35%, 1);
    display: flex;
    
    border-radius: 1rem;
    padding : 1em ;
    box-sizing: border-box;
    
}

.AlertSignContainer {
    width: 10%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    position: relative;
    left: -0.3rem;
    
} 
.AlertBackground {
    background-color: white;
    height: fit-content;
    border-radius: 50%;
    padding: 0.2rem 0.5rem;
    font-size: 1.2em;


}

.Alert {
    /* existing styles */
    transition: background-color 0.3s ease; /* Adds smooth transition */
}

.Alert:hover {
    background-color: hsla(219, 26%, 45%, 0.1); /* Light background color change on hover */
    
}

.AlertBackground {
    /* existing styles */
    transition: transform 0.3s ease; /* Adds smooth scaling transition */
}

.Alert:hover .AlertBackground {
    transform: scale(1.1); /* Slightly enlarges the background on hover */
}
