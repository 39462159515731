.react-calendar {
    width: 99%;
    background: white;
    line-height: 1.125em;
    font-family: inherit;
    border-radius: 0.7rem;
    color: hsla(219, 16%, 35%, 1);
    font-weight: 600;
  }
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding-bottom: 0.1rem;
  }
  
  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none; 
    border-radius: 50%;     
    height: 3em;
    width: 3em;
    font-family: 'Poppins', 'Helvetica Neue', 'Arial', 'Roboto', sans-serif;
    color:hsla(219, 16%, 35%, 1);
    font-weight: 400;
  }
  
  abbr {
    border-radius: 50%;
    display: inline-block;
    width: 2em; /* Adjust as needed */
    height: 2em; /* Adjust as needed */
    line-height: 2em; /* To center the text vertically */
    text-align: center; /* To center the text horizontally */
    border-radius: 50%; /* To make it round */
     /* To hide overflow content */
  }
  
  .react-calendar button:enabled:hover {
    cursor: pointer;
    background-color: white;
    color: hsla(219, 16%, 35%, 1);
    font-family: 'Poppins', 'Helvetica Neue', 'Arial', 'Roboto', sans-serif;
    font-weight: 600;
  }
  
  .react-calendar__navigation {
    display: flex;
    height: 2.7rem;
    margin-bottom: 1em;
    
  }
  
  .react-calendar__navigation button {
    background: none;
   
    }
  
  .react-calendar__navigation button:disabled   {
    background-color: #f0f0f0;
    color: hsla(219, 16%, 35%, 1);
    font-weight: 500;
  }
  
 .react-calendar__navigation__label__labelText--from{
    background-color: white;
    color: hsla(219, 16%, 35%, 1);
    font-weight: 500;
    font-family: 'Poppins', 'Helvetica Neue', 'Arial', 'Roboto', sans-serif;
 }
  
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    font-weight: 600;
  }
  
  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 0.75em;
    color: hsla(0, 0%, 54%, 1);
    cursor:default;
    font-family: 'Poppins', 'Helvetica Neue', 'Arial', 'Roboto', sans-serif;
  }
  
  .react-calendar__month-view__weekdays__weekday {
     
    font-family: 'Poppins', 'Helvetica Neue', 'Arial', 'Roboto', sans-serif;
  }
  
  .react-calendar__month-view__days__day--neighboringMonth abbr{
    color:hsla(0, 0%, 74%, 1);
    font-family: 'Poppins', 'Helvetica Neue', 'Arial', 'Roboto', sans-serif;
  }
  
  
 .react-calendar__tile {
    max-width: 100%;
    padding: 0.6rem 0.35rem;
    background: none;
    text-align: center;
    font-family: 'Poppins', 'Helvetica Neue', 'Arial', 'Roboto', sans-serif;
    
  }
  
  .react-calendar__tile:disabled {
    background-color: #f0f0f0;
    font-family: 'Poppins', 'Helvetica Neue', 'Arial', 'Roboto', sans-serif;
    border-radius: 0%;
    color: red;
  }
  
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: white;
    font-family: 'Poppins', 'Helvetica Neue', 'Arial', 'Roboto', sans-serif;
  }
  
  .react-calendar__tile--now abbr {
    background-color: hsla(174, 40%, 63%, 0.3);
    border-radius: 50%;
    font-family: 'Poppins', 'Helvetica Neue', 'Arial', 'Roboto', sans-serif;
  }
  
  
  .react-calendar__tile--active {
    background-color: white;
    color: white;
  }
  
  .react-calendar__tile--active:enabled:hover abbr,
  .react-calendar__tile--active:enabled:focus abbr{
    background-color: hsla(174, 40%, 63%, 1);
    color: white;
    border-radius: 50%;
  }
  
 
  .react-calendar__navigation button:nth-of-type(3) span {
    pointer-events: none; 
    font-weight: 600;
    
    }
.react-calendar__month-view__days__day {
width: fit-content;
height: fit-content;
}
abbr {
    text-decoration: none;
    color: inherit;
}

.react-calendar__tile--active abbr {
  background-color: hsla(174, 40%, 63%, 1) !important;
  color: white !important;
  border-radius: 50% !important;
}

.test {
  color: red;
  
}