p.bubble {
	position: relative;
	width: fit-content;
    height: fit-content;
	text-align: center;
	line-height: 1.4em;
	padding: 0.5rem;
	background-color: #fff;
	border: 2px solid   hsla(175, 17%, 71%, 0.82);
	border-radius: 30px;
	font-family: sans-serif;
    position: relative;
    bottom: min(8rem,18vh);
    right: min(3rem,10vh,10vw);
	font-size: min(3vw,0.9rem,2.5vh);
	
    
}



p.bubble:before,
p.bubble:after {
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
}

p.speech:before {
	left: 30px;
	bottom: -50px;
	border: 25px solid;
	border-color: hsla(174, 40%, 63%, 0.9) transparent transparent hsla(174, 40%, 63%, 0.9);
}

p.speech:after {
	left: 38px;
	bottom: -30px;
	border: 15px solid;
	border-color: #fff transparent transparent #fff;
}







