.Book {
    background-color: white;
    width:16rem;
    height:fit-content;
    right:11vw;
    top: 0rem;
    position: absolute;
    border-radius: 9%;
    display:flex;
    flex-direction: column;
    font-family: 'Montserrat', 'Open Sans', 'Roboto', 'Raleway', 'Lato', 'Nunito', 'Poppins', 'Quicksand', 'Ubuntu', 'Work Sans', 'Source Sans Pro', sans-serif;
    padding: 2vh 1.5vh;
}

.BookHead {
    color: hsla(219, 16%, 35%, 1);
    font-weight: 700;
    margin-top: 1vh;
    }
.BookText{
    font-size: 0.75em;
    line-height: 1.5em;
    font-family: 'Poppins', 'Helvetica Neue', 'Arial', 'Roboto', sans-serif;
    font-weight: 400;
    color: hsla(0, 0%, 53%, 1);
    margin-top: 0.5vh;
    margin-bottom: 0.2vh;

}

.arrow-button {
    display: flex;
    color: #C2FFE9;
    background-color: hsla(174, 40%, 63%, 1);
    padding: 3% 4%;
    border-radius: 2vh;
    transition: all .3s ease;
    cursor: pointer;
    align-items: center;
    font-size: 0.8em;
    border-width: 0.05vh;
    font-weight: 700;
    width: 95%;
    margin-top: 2vh;
   
}

.arrow-button > .arrow {
    width: 6px;
    height: 6px;
    border-right: 2px solid #C2FFE9;
    border-bottom: 2px solid #C2FFE9;
    position: relative;
    transform: rotate(-45deg);
    margin: 0 6px;
    transition: all .3s ease;
}

.arrow-button > .arrow::before {
    display: block;
    background-color: currentColor;
    width: 3px;
    transform-origin: bottom right;
    height: 2px;
    position: absolute;
    opacity: 0;
    bottom: calc(-2px / 2);
    transform: rotate(45deg);
    transition: all .3s ease;
    content: "";
    right: 0;
}

.arrow-button:hover > .arrow {
    transform: rotate(-45deg) translate(4px, 4px);
    border-color: text-hover-color;
}

.arrow-button:hover > .arrow::before {
    opacity: 1;
    width: 8px;
}

.arrow-button:hover {
    background-color: hsla(174, 40%, 63%, 1);
   
    color: #fff;
}

.BookText{
    font-size: 0.85rem;
    margin-top: 0.3rem;
    line-height: 1.1rem;
}

.nonClickable {
    cursor: not-allowed;
  }
  
@media(max-width:1470px) {
    .Book{
      display: none;
    }
   }

