.dialogOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
}

.dialogContainer {
    background-color: #fff;
    width: 80%;
    max-width: 500px;
    border-radius: 8px;
    overflow: hidden;
}

.dialogHeader {
    background-color: hsla(174, 40%, 63%, 1);
    color: hsla(229, 17%, 24%, 1);
    font-weight: 700;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.closeDialog {
    cursor: pointer;
}

.dialogMain {
    padding: 16px;
}

.dialogFooter {
    padding: 16px;
}
