.AS2{
    width: 100%;
    min-height: 100vh;
    background-color: hsla(0, 0%, 95%, 1);
    font-family: 'Poppins', 'Helvetica Neue', 'Arial', 'Roboto', sans-serif;
    display: flex;
    flex-direction: column;
}

.AS2BigContainer{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: start;
}   

.AS2SmallContainer{
    width: min(35rem,95%);
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.EditContainer{
    width: 93%;
    display: flex;
    padding: 0.3rem 0.8rem;
    font-size: 0.7rem;
    color:hsla(219, 16%, 35%, 1);
    background-color: white;
    border-radius: 0.5rem;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    margin: 0.5rem 0;
}
.EditButton{
    background-color: hsla(174, 40%, 63%, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2em;
    width: 6em;
    border-radius: 0.3rem;
    font-size: inherit;
    color: inherit;
    font-weight: inherit;
    font-family: inherit;
    border: none;
    cursor: pointer;
}

