.AS {
    background-color: hsla(0, 0%, 95%, 1);
    min-height: 100vh;
    height: fit-content;
    position: relative;
    

}

.ASContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    font-family: 'Poppins', 'Helvetica Neue', 'Arial', 'Roboto', sans-serif;
    
    
}

.MiniAppoContainer {
    width: min(34.4rem,95%);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
    
}
.ASText {
    margin:0.3rem 0;
    font-size: 0.8rem;
    width: 100%;
    font-weight: 500;
    color:hsla(229, 17%, 24%, 1)
}
.DayPicker {
    width:100%;
    display:flex;
    position: relative;
    font-size: 0.65rem;
    
  }
  .DayPickerBox {
    background-color: white;
    border-radius: 15px;
    width:15%;
    aspect-ratio: 3 / 2;
    margin:0.4rem 0.2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 0.5rem;
    padding-top: 0rem;
    font-family: 'Inter', 'Roboto', 'Helvetica Neue', 'Arial', sans-serif;;
    color:hsla(229, 17%, 24%, 1);
    position: relative;
    font-weight: 500;
   }

   .Day{
    margin-bottom: 0.5rem;
    pointer-events: none;
   }
   .CheckBoxA {
    width: 0.6rem;
    height: 0.6rem;
    border-color: hsla(219, 16%, 35%, 1);
    border-radius: 50%;
    border-width: 0.1rem;
    border-style: solid; 
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.6rem;
    cursor: pointer;
    background-color: white;
    pointer-events: none;
    
}


    .activeCheckBox{
        background-color: hsla(219, 16%, 35%, 1);
 }

 .Day {
    position: relative;
    top:0.2rem;
}

.SettingsInput {
    width: 100%;
    background-color: white;
    border:none;
    border-radius: 0.2rem;
    padding: 0.5rem 1rem;
    margin:0.3rem 0rem;
    box-sizing: border-box;
}
.submitButtonAS {
    width: 100%;
    background-color: hsla(174, 40%, 63%, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    border: none;
    margin: 0.3rem;
    padding: 0.45rem 0rem;
    border-radius: 0.3rem;
    cursor: pointer;
}
.submitButtonASLoading {
    width: 100%;
    background-color: hsla(174, 40%, 63%, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    border: none;
    margin: 0.3rem;
    padding: 0.45rem 0rem;
    border-radius: 0.3rem;
    cursor: wait;
}

.DayPickerBox:hover {
    background-color: hsla(219, 16%, 35%, 0.1); 
}
.submitButtonAS:hover {
    background-color: hsla(174, 40%, 53%, 1);
}

@media(width<500px) {
    .DayPickerBox{
        aspect-ratio: 9/8;
    }
}



