.Card {
    display:grid;
    width: 100%; 
      
    height: 8.4rem;
    grid-template-columns:1fr 6.33fr ;
    grid-template-rows:1fr 1fr 1fr 1fr ;
    background-color: hsla(0, 0%, 100%, 1);
    border-radius: 20px;
    padding-top: 2vh;
    padding-left: 1vw;
    padding-right:3vw ;
    margin: 0.2rem;
    transition: transform 0.3s ease;
    cursor: pointer;
    box-sizing: border-box;
    position: relative;
}
.ProfilePicContainer{
    grid-row: 1/3;
    grid-column: 1;
    display:flex;
    flex-direction: column;
    justify-content: start;
    margin-right: 1vw;
    align-items: end;
}

.ProfilePic {
    height:min(3.35rem,12.5vw);
    width:min(3.35rem, 12.5vw);
    border-radius: 50%;
    object-fit:cover;
    position:flex-end;
   
}
.NameRating {
    grid-row: 1;
    grid-column: 2/-1;
    display:flex;
    justify-content:space-between;
    padding-left:1vw; 
    padding-right:1vw;

 }

 .Name {
position: relative;
font-size: 1.2rem;
font-weight: 600;
letter-spacing: 0px;
color: hsla(233, 15%, 11%, 1);
 }


 .RatingNumber{
font-family: 'Inter', 'Roboto', 'Helvetica Neue', 'Arial', sans-serif;;
font-weight: 600;
letter-spacing: 0px;
color: hsla(233, 15%, 11%, 1);
font-size: 0.8rem;

}

.Rating {
    display:flex;
}

.CardText {
    grid-row: 2;
    grid-column: 2/-1;
    font-size: 0.9em;
    font-weight: 300;
    letter-spacing: 0em;
    text-align: left;
    color: hsla(0, 0%, 53%, 1);
    margin-top: 1vh;
    position: relative;
    left: 1vw; 
 }

 .Specs{
    grid-row:3;
    grid-column: 2/-1;
    display:flex;
    margin-top: 0.25rem;
    flex-shrink: 0;
    justify-content: center;
    
    

    

 }
 .S1, .S2, .S3 { 

display: flex;
justify-content: center;
align-items: center;
border-radius: 2vw;
font-weight:400;
color: hsla(219, 16%, 35%, 1);
margin:0.4vw;
font-size: min(0.8rem, 2vw);
padding: 0.5vh 0.8vw ;
padding-top:0.2vh;
padding-bottom: 0.2vh;
max-width: 7rem;


text-align: center;








}

.S1{
    background-color: hsla(29, 100%, 89%, 0.4);
    ;
}

.S2 {
    background-color: hsla(341, 65%, 88%, 0.3);
}
.S3 {
    background-color: hsla(184, 44%, 81%, 0.3);

}

.Card:hover {
    /* Example: scale the card a little on hover */
    transform: scale(1.02);
    /* Add any other hover effects you desire, like changing the background color, etc. */
    background-color: rgba(0,0,0,0.05); /* optional */
}

.CardPrice {
    display: flex;
    justify-content: end;
    align-items: center;
    justify-self: end;
    flex:1 0 auto;
    box-sizing: border-box;
    padding: 0.5rem;
    position: relative;
    top:0.2rem;
    color: hsla(0, 0%, 65%, 1);
    font-weight: 600;
    font-size: min(3vw, 0.8rem);
}

.SDiv{
width: 100%;
    overflow-wrap: break-word;
}

@media (max-width:500px) {
    .Card{
        height: 10rem;
    }
}