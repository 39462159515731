.MuiPaper-root {
    width:fit-content;
    height: fit-content;
    position:relative;
    border-radius: 1rem;
    

}

.MuiTypography-root {
    background-color: hsla(174, 40%, 63%, 1);
    height: 4%;
    border:none
    
}

.AvatarContainer{
    width: 100%;
   ;
    
}

.css-ypiqx9-MuiDialogContent-root {
    padding: 0rem 0rem;
}

.AvatarContainer img{
    margin-left: 30%;
    margin-top: 2%;
}



